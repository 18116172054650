import React, {useState, useRef, cloneElement, useEffect} from 'react';
import useOutsideClick from "../../../../Hooks/useOutsideClick";
import ImgLeft from "./Modals/ImgLeft";
import ImgRight from "./Modals/ImgRight";

import {useKeysNavigation} from "../../../../Hooks/useKeysNavigation";

export default function ScenarioModal( props ) {
    const [slide, setSlide] = useState( 0 );
    const ref = useRef( null );

    useOutsideClick( ref, () => {
        props.handleClose();
    } );

    const slidesCount = props.scenario.presentation.length;

    const slideLayouts = {
        'img-left':         <ImgLeft/>,
        'img-right':        <ImgRight/>
    };

    const handlePrev = () => {
        if ( slide !== 0 ) {
            setSlide( slide - 1 );
        }
    };

    const handleNext = () => {
        if ( slide !== slidesCount - 1 ) {
            setSlide( slide + 1 );
        }
    };

    useKeysNavigation( handlePrev, handleNext );

    return (
        <div ref={ref} className="modal modal--l">

            <button
                className={"modal__close d--flex dark"}
                onClick={() => props.handleClose()}
            >
                <span className={"mdi mdi-close m--a"}> </span>
            </button>

            <div className="modal__body modal__scenario">
                {
                    cloneElement(
                        slideLayouts[ props.scenario.presentation[ slide ].layout ], {
                            image:        props.scenario.presentation[ slide ].image,
                            scenario:     props.scenario.code,
                            slide:        slide,
                            heading:      props.scenario.presentation[ slide ].heading,
                            content:      props.scenario.presentation[ slide ].content,
                            handleChoose: () => {
                                props.handleClose();
                                props.handleChoose();
                            }
                        }
                    )
                }
            </div>

            <div className="modal__slider d--flex jc--sb">
                <button
                    className="button-clear"
                    onClick={() => {
                        handlePrev()
                    }}
                >
                    <span className={"mdi mdi-chevron-left" + ( slide === 0 ? " disabled" : "" )}/>
                </button>

                <div className="slider__dots d--flex ai--c">
                    {
                        ( new Array( slidesCount ) ).fill( null ).map( ( t, i ) => (
                            <button
                                key={i}
                                className={"button-clear slider__dot" + ( i === slide ? " active" : "" )}
                                onClick={() => slide !== i && setSlide( i )}
                            />
                        ) )
                    }
                </div>

                <button
                    className="button-clear"
                    onClick={() => {
                        handleNext()
                    }}
                >
                    <span className={"mdi mdi-chevron-right" + ( slide === slidesCount - 1 ? " disabled" : "" )}/>
                </button>

            </div>
        </div>

    )
}