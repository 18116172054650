import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import * as axios from "axios";
import {apiURL} from "../../../env";
import {gameModulesLoad} from "../../../Store/actions/gameActions";
import {authTeamLoaded} from "../../../Store/actions/authActions";
import {notificationsLoaded} from "../../../Store/actions/notificationsActions";
import {set} from "immutable";
import Channels from "./Channels";
import Messages from "./Messages";
import InputBar from "./InputBar";
import {unseenMessagesCountLoaded} from "../../../Store/actions/chatActions";

function Chat( props ) {
    const messagesRef = useRef( null );
    const {t} = useTranslation();

    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);

    const [messages, setMessages] = useState( [] );
    const [selected, setSelected] = useState( null );
    const [hasOld, setHasOld] = useState(false);

    const [shouldScrollDown, setShouldScrollDown] = useState( false );

    useEffect( () => {
        if ( shouldScrollDown && messagesRef.current ) {
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight - messagesRef.current.clientHeight;
            setShouldScrollDown( false );
        }
    }, [messages] )

    const loadUnseenMessagesCount = () => {
        axios( {
            method: "get",
            url:    apiURL + '/chat/unseen-messages',
        } )
            .then( ( response ) => {
                props.dispatch( unseenMessagesCountLoaded( response.data.unseen ) );
            } )
            .catch( ( error ) => {
                console.error( error );
            } );
    };

    const mergeMessages = ( firstArray, secondArray ) => {
        console.log({firstArray, secondArray});
        const mergedArray = [...firstArray, ...secondArray];
        console.log(mergedArray)
        let set = new Set();
        let unionArray = mergedArray.filter( item => {
            if ( !set.has( item.id ) ) {
                set.add( item.id );
                return true;
            }
            return false;
        }, set );

        unionArray.sort( ( first, second ) => {
            return first.id - second.id
        } );

        return unionArray;
    }

    const loadMessages = (lastMessage = (messages.length > 0 ? messages[ messages.length - 1 ].id : ''), init = false) => {

        axios( {
            method: 'get',
            url:    `${apiURL}/chat/channels/${selected}?`+ (init ? `init=true` : `last=${lastMessage}`),
        } )
            .then( ( response ) => {
                setHasOld(response.data.old);
                if ( messagesRef.current && ( messagesRef.current.scrollHeight - messagesRef.current.clientHeight - messagesRef.current.scrollTop ) < 100 ) {
                    setShouldScrollDown( true );
                }
                setLoading(false);
                loadUnseenMessagesCount();
                setMessages( ( oldMessages ) => mergeMessages( response.data.messages, oldMessages ) );
            } )
            .catch( ( error ) => {
                console.error( error );
            } );
    }

    useEffect( () => {
        if ( selected === null ) return;
        setLoading(true);
        setMessages([]);
        loadMessages('',true);
        const refresh = setInterval( loadMessages, 8000 );

        return ( () => {
            clearInterval( refresh );
        } )
    }, [selected] );

    const onOlderClickHandler = () => {
        console.log(messages);
        const firstMessage = messages.length > 0 ? messages[ 0 ].id : '';

        axios( {
            method: 'get',
            url:    `${apiURL}/chat/channels/${selected}?first=${firstMessage}`
        } )
            .then( ( response ) => {
                setMessages( ( oldMessages ) => mergeMessages( response.data.messages, oldMessages ) );
                setHasOld(response.data.old)
            } )
            .catch( ( error ) => {
                console.error( error );
            } );
    }

    return (
        <div className={"wrapper__content m--0a d--flex fd--c"}>
            {/*<h1 className={"mt--3 heading--m mb--3"}>{t('chat_heading')}</h1>*/}
            <div className="chat-container">
                <div className="chat-channels">
                    <Channels
                        selected={selected}
                        setSelected={setSelected}
                    />
                </div>
                <div className="chat-discussion">
                    <Messages
                        auth={props.auth}
                        ref={messagesRef}
                        loading={loading}
                        messages={messages}
                        // skip={skip}
                        onOlderClick={onOlderClickHandler}
                        hasOld={hasOld}
                    />
                    {
                        selected ? (
                            <InputBar
                                channel={selected}
                                uploading={uploading}
                                onSubmit={( message, setMessage, file, setFile ) => {
                                    if (file !== null) setUploading(true);
                                    const lastMessage = messages.length > 0 ? messages[ messages.length - 1 ].id : '';

                                    const data = new FormData();
                                    data.append('message', message);
                                    data.append('file', file);

                                    axios( {
                                        method: 'post',
                                        url:    apiURL + `/chat/channels/${selected}?last=${lastMessage}`,
                                        data
                                    } )
                                        .then( ( response ) => {
                                            if (file !== null) setUploading(false);
                                            setMessage( '' );
                                            setFile(null);
                                            setShouldScrollDown( true );
                                            setMessages( ( oldMessages ) => mergeMessages( response.data.messages, oldMessages ) );
                                        } )
                                        .catch( ( error ) => {
                                            if (file !== null) setUploading(false);
                                            console.error( error );
                                        } );
                                }}
                            />
                        ) : null
                    }
                </div>
            </div>
        </div>
    )
}

function mapStateToProps( state ) {
    const {auth} = state;

    return {auth}
}

export default connect(
    mapStateToProps
)( Chat );