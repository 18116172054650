import React, {useState, useRef, cloneElement, useEffect} from 'react';
import useOutsideClick from "../../../../Hooks/useOutsideClick";
import scenarios from "./data";
import {useTranslation} from "react-i18next";

export default function ScenarioSelectModal( props ) {
    const ref = useRef( null );

    const [confirm, setConfirm] = useState( null );


    const {t} = useTranslation();

    useOutsideClick( ref, () => {
        props.handleClose();
    } );

    return (
        <>
            <div className="backdrop info"/>
            <div className="modal-wrapper">
                <div ref={ref} className="modal modal--l">
                    <button
                        className={"modal__close d--flex dark"}
                        onClick={() => props.handleClose()}
                    >
                        <span className={"mdi mdi-close m--a"}> </span>
                    </button>

                    <div className="modal__body modal__scenario select">
                        <div className="modal__inner">
                            <h2 className={"mb--3"}>{t( 'scenario_select' )}</h2>

                            <div className="d--flex fw--w card-container">
                                {
                                    scenarios.map( ( s, i ) => (

                                        <div
                                            className={"card scenarios-item scenario__select d--flex fd--c jc--sb"}
                                            key={i + 1}
                                            onClick={( e ) => {
                                                setConfirm( i );
                                            }}
                                        >
                                            <div className="d--flex ai--fs sm:fd--c jc--sb sm:jc--fs h--80">
                                                <img src={s.image} alt=""/>
                                                <h2 className={"mt--3 ml--2 mr--2 sm:mt--2"}>{t( `scenarios_${s.code}_modal_name` )}</h2>
                                            </div>

                                            <h3 className={"mb--2 ml--2 mr--2"}>{t( `scenarios_${s.code}_name` )}</h3>
                                        </div>
                                    ) )
                                }
                            </div>

                        </div>

                    </div>

                    {
                        confirm !== null && (
                            <div className="modal__confirmation">
                                <div className="m--a text--center">
                                    <h2 className="mb--3">{t( 'scenario_select' )}</h2>
                                    <p className="mb--3">{t( 'scenario_select_confirm' )} <strong><span
                                        className={"text--featured"}>{scenarios[ confirm ].name}</span></strong>?</p>
                                    <button
                                        className="btn btn--m btn--text mr--1"
                                        onClick={() => setConfirm( null )}
                                    >
                                        {t( 'close' )}
                                    </button>
                                    <button
                                        className="btn btn--m btn--secondary"
                                        onClick={() => {
                                            props.handleSelect( confirm )
                                        }}
                                    >
                                        {t( 'select-confirmation' )}
                                    </button>
                                </div>

                            </div>
                        )
                    }
                </div>
            </div>
        </>


    )
}