import React, {useState} from 'react';
import {gameModuleClose, gameSectionUnlockNext} from "../../../../Store/actions/gameActions";
import SectionFinishedContent from "../SectionFinished/SectionFinishedContent";
import {useTranslation} from "react-i18next";

export default function Outro( props ) {

    const [step, setStep] = useState( 1 );

    const {t} = useTranslation();

    return (

        <div className="d--iflex fd--c ai--fs question-container">
            <div className="backdrop info"></div>

            <div className="modal-wrapper">
                <div className="modal modal--l modal__outro blue">

                    {
                        step === 1 && (
                            <div className="modal__body d--flex h--100">
                                <div className="modal__left w--50">
                                    <div className="modal__inner">

                                        <h2 className={"text--center mb--3 mt--3"}>{t('quiz_modal_results_heading')}</h2>

                                        <div className="d--flex jc--c mb--2">
                                            <img src="img/supporters.svg" alt="" className="mr--3 supporters__img"/>
                                            <p className="text--supporters as--c">{props.results.supporters}</p>
                                        </div>

                                        <div className="outro__results mb--3 mt--3">

                                            <div className={"d--flex outro__result-container jc--sa"}>
                                                <div className={"outro__item"}>
                                                    <div className="outro__icon d--flex">
                                                        <img src="img/check-outro.svg" className={"m--a"} alt=""/>
                                                    </div>

                                                    <div className="outro__value text--center mt--2">
                                                        <h3 className={'text--black '}>{props.results.success}%</h3>
                                                        <p className={'text--black '}>{t('quiz_modal_results_correct')}</p>
                                                    </div>
                                                </div>

                                                <div className={"outro__item"}>
                                                    <div className="outro__icon d--flex">
                                                        <img src="img/bonus.svg" className={"m--a"} alt=""/>
                                                    </div>

                                                    <div className="outro__value text--center mt--2">
                                                        <h3 className={'text--black '}>{props.results.bonus}</h3>
                                                        <p className={'text--black '}>{t('bonus')}</p>
                                                    </div>
                                                </div>

                                                <div className={"outro__item"}>
                                                    <div className="outro__icon d--flex">
                                                        <img src="img/coin-outro.svg" className={"m--a"} alt=""/>
                                                    </div>

                                                    <div className="outro__value text--center mt--2">
                                                        <h3 className={'text--black '}>{props.results.credit}</h3>
                                                        <p className={'text--black '}>{t('quiz_modal_results_heroes')}</p>
                                                    </div>
                                                </div>

                                                {/*<div className={"outro__item"}>
                                        <div className="outro__icon d--flex">
                                            <img src="img/supporters-outro.svg" className={"m--a"} alt=""/>
                                        </div>

                                        <div className="outro__value text--center mt--2">
                                            <h3>20</h3>
                                            <p>{t('quiz_modal_results_supporters')}</p>
                                        </div>
                                    </div>*/}
                                            </div>
                                        </div>

                                        <div className="outro__buttons text--center">
                                            <button
                                                onClick={() => {
                                                    setStep(2);
                                                }}
                                                className="btn btn--m btn--danger btn--shadow">
                                                {t('continue')}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="modal__right w--50 h--100">
                                    <img src={`img/quiz/M${props.level}.png`} className="modal__marvin" alt="Level 1 Outro"/>
                                </div>


                            </div>
                        )
                    }
                    {
                        step === 2 && (
                            <>

                                <img src="#" className={"img--bg-full theory"} alt=""/>
                                <SectionFinishedContent
                                    handleBackToMenu={props.handleBackToMenu}
                                    handleContinue={props.handleContinue}
                                />
                            </>
                        )
                    }

                </div>
            </div>
            <img src="/img/bg-quiz.png" className={"img--bg-full"} alt=""/>
        </div>
    )
}