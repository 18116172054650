import React, {useState} from 'react';
import {useDrag} from "react-dnd";

export default function Word( {word, type, handleClick} ) {
    const [{opacity}, drag] = useDrag( {
        item:    {word, type},
        collect: ( monitor ) => ( {
            opacity: monitor.isDragging() ? 0.4 : 1,
        } ),
    } )

    return (
        <div
            ref={drag}
            style={{opacity}}
            className="connect__item mr--2 mb--2 sm:mr--1 sm:mb--1"
            onClick={handleClick}
        >
            <span>{word}</span>
        </div>
    )
}