import React from 'react';
import {gameModuleClose} from "../../../Store/actions/gameActions";
import {connect} from "react-redux";
import {FacebookProvider, Share} from "react-facebook";
import * as axios from "axios";
import {apiURL} from "../../../env";
import {useTranslation} from "react-i18next";

export default function Diploma( props ) {

    const classes = [
        'socialneeds',
        'ideageneration',
        'visionary',
        'empathy',
        'idea',
        'sustainable',
    ];

    const {t} = useTranslation();

    return (
        <>
            <div className="modal-wrapper">
                <div className="modal modal--l modal__diploma">

                    <button
                        className={"modal__close d--flex dark"}
                        onClick={() => props.handleClose()}
                    >
                        <span className={"mdi mdi-close m--a"}> </span>
                    </button>

                    <div className={"modal__body " + classes[ props.type - 1 ]}>
                        <div className="modal__inner d--flex">
                            <div className="w--50">
                                <h2 className={"text--center mb--3"}>{t( 'diploma_modal_heading' )}</h2>

                                <p className={"text--center"}>
                                    {t( 'diploma_modal_text1' )}
                                </p>
                                <p className={"mt--2 mb--2 text--center"}>
                                    {t( 'diploma_modal_text2' )}
                                </p>

                                <div className="d--flex jc--fs">
                                    {
                                        props.close ? (
                                            <button
                                                className={"mt--3 mr--2 btn btn--m btn--danger btn--shadow d--block"}
                                                onClick={() => props.handleClose()}
                                            >
                                                {t( 'close' )}
                                            </button>
                                        ) : (
                                            <button
                                                className={"mt--3 mr--2 btn btn--m btn--success btn--shadow d--block"}
                                                onClick={() => props.handleClose()}
                                            >
                                                {t( 'continue' )}
                                            </button>
                                        )
                                    }

                                    {
                                        props.handleShare && (
                                            <button
                                                className={"mt--3 btn btn--m btn--white btn--shadow d--flex btn--share"}
                                                onClick={() => {
                                                    props.handleShare();
                                                }}
                                            >
                                                {t( 'share' )}
                                            </button>
                                        )
                                    }
                                </div>
                            </div>

                            <img className={"img__right"} src={`img/diploma/diploma_${classes[ props.type - 1 ]}.png`}
                                 alt=""/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="backdrop info"></div>
        </>
    )
}