import React from 'react';


import {BrowserRouter, Redirect, Route} from "react-router-dom";

import FirstPassword from "./Components/Auth/FirstPassword/FirstPassword";
import ResetPassword from "./Components/Auth/ResetPassword/ResetPassword";

import {connect} from "react-redux";
import AuthWrapper from "./Components/AuthWrapper";

import Login from "./Components/Auth/Login";

import Office from "./Components/Screens/Office/Office";
import Settings from "./Components/Screens/Settings/Settings";
import Results from "./Components/Screens/Results/Results";
import Files from "./Components/Screens/Files/Files";
import Game from "./Components/Screens/Game/Game";
import LoginWrapper from "./Components/LoginWrapper";
import TeamLoading from "./Components/Auth/TeamLoading";
import Chat from "./Components/Screens/Chat/Chat";


function Router( props ) {
    const {auth} = props;

    const allowAuth = ( component ) => {
        if ( auth.authenticated && auth.team ) {
            return (
                <AuthWrapper>
                    {component}
                </AuthWrapper>
            )
        } else if ( auth.authenticated && !auth.team ) {
            return (
                <LoginWrapper>
                    <TeamLoading/>
                </LoginWrapper>
            )
        } else {

            return (
                <Redirect to={{pathname: "/login"}}/>
            )
        }
    }

    const allowGuest = ( component ) => {
        if ( !auth.authenticated || !auth.team ) {
            return (
                <LoginWrapper>
                    {component}
                </LoginWrapper>
            );
        } else {
            return (
                <Redirect to={{pathname: "/"}}/>
            )
        }
    }


    return (
        <BrowserRouter>
            <Route path="/login" exact={true} render={() => {
                return allowGuest( ( <Login/> ) )
            }}/>
            <Route path="/first-password" exact={true} render={() => {
                return allowGuest( <FirstPassword/> )
            }}/>

            <Route path="/reset-password" exact={true} render={() => {
                return allowGuest( <ResetPassword/> )
            }}/>

            <Route path="/reset-password/:token" exact={true} render={() => {
                return allowGuest( <ResetPassword/> )
            }}/>

            <Route path="/" exact={true} render={() => {
                return allowAuth( <Game staticSidebar={true} time={Date.now()}/> )
            }}/>

            <Route path="/office" exact={true} render={() => {
                return allowAuth( <Office/> )
            }}/>

            <Route path="/results" exact={true} render={() => {
                return allowAuth( <Results/> )
            }}/>

            <Route path="/files" exact={true} render={() => {
                return allowAuth( <Files/> )
            }}/>

            <Route path="/settings" exact={true} render={() => {
                return allowAuth( <Settings/> )
            }}/>

            <Route path="/chat" exact={true} render={() => {
                return allowAuth( <Chat/> )
            }}/>
        </BrowserRouter>
    );
}

function mapStateToProps( state ) {
    const {auth} = state;

    return {
        auth
    }
}

export default connect(
    mapStateToProps
)( Router );
