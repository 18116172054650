import React, {useState} from 'react';
import {useDrop} from "react-dnd";
import WordConnected from "./WordConnected";
import {useTranslation} from "react-i18next";

export default function WordPlaceholder( {index, word, onDrop, handleWordClick} ) {
    const [{isOver, canDrop}, drop] = useDrop( {
        accept:  ['word', 'word-connected'],
        drop:    onDrop,
        collect: ( monitor ) => ( {
            isOver:  monitor.isOver(),
            canDrop: monitor.canDrop(),
        } ),
    } )

    const {t} = useTranslation();

    return (
        <div ref={drop} className="connect-item__column left mr--3 sm:mr--1 d--flex">
            {
                word ? (
                    <WordConnected
                        index={index}
                        word={word}
                        handleClick={() => handleWordClick(word, index)}
                        type={'word-connected'}
                    />
                ) : (
                    <div className="connect__item w--100 text--center empty">
                        <span>I{t( 'quiz_invalid_answer' )}</span>
                    </div>
                )
            }
        </div>
    )
}