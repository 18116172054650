import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";

import {gameLogout, gameModuleOpen, gameModuleReset, gameModulesLoad} from "../../../../Store/actions/gameActions";
import * as axios from "axios";
import {apiURL} from "../../../../env";
import {authLogout, authTeamLoaded} from "../../../../Store/actions/authActions";
import Loader from "../Tasks/Loader";
import {useTranslation} from "react-i18next";
import Level from "./Level";
import {notificationsLoaded} from "../../../../Store/actions/notificationsActions";
import {
    badgesCountSet,
    CREDIT_SET,
    creditSet,
    progressSet,
    supportersSet
} from "../../../../Store/actions/creditActions";
import {unseenMessagesCountLoaded} from "../../../../Store/actions/chatActions";
import store from "../../../../index";

function Dashboard( props ) {

    const {t} = useTranslation();
    const [loading, setLoading] = useState( true );
    const [maxUnlockedLevel, setMaxUnlockedLevel] = useState( 0 );

    const findMaxUnlockedLevel = () => {
        for ( let i = 0; i < 7; i++ ) {
            if ( props.game.moduleStatus[ i ] !== "locked" && maxUnlockedLevel < i ) {
                setMaxUnlockedLevel( i );
            }
        }
    }

    const loadModules = () => {
        axios( {
            method: "get",
            url:    apiURL + '/modules',
        } )
            .then( ( response ) => {
                if ( ( response.data.team.individual_game !== props.auth.team.individual_game ) || ( props.auth.team.individual_game && props.auth.student == null ) ) {
                    localStorage.removeItem( 'access_token' );
                    store.dispatch( authLogout() );
                    store.dispatch( gameModuleReset() );
                }
                setLoading( false );
                props.dispatch( gameModulesLoad( response.data.modules ) );
                props.dispatch( authTeamLoaded( response.data.team ) );
                props.dispatch( notificationsLoaded( response.data.team.notifications ) );

                props.dispatch( creditSet( response.data.team.coins ) );
                props.dispatch( supportersSet( response.data.team.supporters ) );
                props.dispatch( badgesCountSet( response.data.team.badges_count ) );
                props.dispatch( progressSet( response.data.team.progress ) );
            } )
            .catch( ( error ) => {
                console.log(error);
                if ( error.response ) {
                    if ( error.response.status == 401 ) {
                        localStorage.removeItem( 'access_token' );
                        props.dispatch( authLogout() );
                        props.dispatch( gameLogout() );
                    }
                }
            } );
    };

    useEffect( () => {
        setLoading( true );
        loadModules();

        props.handleSidebarSticky( window.innerWidth >= 1024 );

        const refresh = setInterval( () => {
            loadModules();
        }, 8000 );


        return ( () => {
            clearInterval( refresh );
            props.handleSidebarSticky( false );
        } )
    }, [] );

    useEffect( () => {
        findMaxUnlockedLevel()
    }, [props.game.moduleStatus] );

    if ( loading ) {
        return ( <Loader/> )
    }

    return (
        <div className={"wrapper__dashboard m--0a d--flex fd--c"}>


            <div className="d--flex jc--sb ai--c m:fd--c m:ai--fs">
                <h1 className={"mt--3 heading--m mb--3"}>{t( 'dash_heading' )}</h1>
                {

                    (
                        ( props.auth.student && props.game.sectionStatus[ 0 ][ 'intro' ] && props.game.sectionStatus[ 0 ][ 'intro' ][ props.auth.student.id ] === 'completed' )
                        ||
                        ( props.game.sectionStatus[ 0 ][ 'intro' ] === 'completed' )
                    ) ? (
                        <div className={'d--flex m:mb--2'}>
                            <button
                                className={"dashboard__intro white d--flex ai--c mr--1"}
                                onClick={() => {
                                    props.dispatch( gameModuleOpen( 0, 'video' ) );
                                }}
                            >
                                <img src="img/play.svg" className={"play-icon mr--2"} alt=""/>
                                {t( 'dash_intro_btn' )}
                            </button>
                            <button
                                className={"dashboard__intro blue text--center mr--1"}
                                onClick={() => {
                                    props.dispatch( gameModuleOpen( 0, 'faq' ) );
                                }}
                            >
                                {t( 'dash_about_btn' )}
                            </button>

                            <div className="btn-social d--flex">
                                <a href="https://www.facebook.com/InnoSchoolLearningSystem/" target={"_blank"}
                                   className={"mr--1"}>
                                    <img src="img/social/facebook_logo.svg" alt=""/>
                                </a>

                                <a href="https://www.instagram.com/inno_school_eu/" target={"_blank"}>
                                    <img src="img/social/instagram_logo.png" alt=""/>
                                </a>
                            </div>


                        </div>
                    ) : (
                        <button
                            className={"dashboard__intro blue animate d--flex ai--c"}
                            onClick={() => {
                                props.dispatch( gameModuleOpen( 0, 'video' ) );
                            }}
                        >
                            <img src="img/play-white.svg" className={"play-icon mr--2"} alt=""/>
                            {t( 'dash_start_btn' )}
                        </button>
                    )
                }
            </div>

            <div className="card-container d--flex fw--w">
                {
                    ( new Array( 7 ).fill( null ) ).map( ( a, i ) => {
                        if ( i === 0 ) return null;
                        return (
                            <Level
                                key={i}
                                level={i}
                                {...module}
                                status={props.game.moduleStatus[ i ]}
                                sections={props.game.sectionStatus[ i ]}
                                student={props.auth.student ? props.auth.student : null}
                                maxUnlockedLevel={maxUnlockedLevel}
                                handleOpenSection={( section ) => {
                                    props.dispatch( gameModuleOpen( i, section ) )
                                }}
                            />
                        )
                    } )
                }
            </div>
            <img src={"/img/bg-admin.jpg"} alt="Admin Background" className={"img--bg-full"}/>
        </div>
    )
}

function mapStateToProps( state ) {
    const {auth, game, flashes} = state;


    return {auth, game, flashes}
}

export default connect(
    mapStateToProps
)( Dashboard );