import React, {useState} from 'react';
import {
    gameModuleClose,
    gameModulesLoad,
    gameSectionFinished,
    gameSectionNext
} from "../../../../Store/actions/gameActions";
import {connect} from "react-redux";
import ModuleFinish from "../ModuleFinish";
import * as axios from "axios";
import {apiURL} from "../../../../env";
import {authTeamLoaded} from "../../../../Store/actions/authActions";
import {useTranslation} from "react-i18next";
import scenarios from "../ScenariosSelect/data";
import InfoPanel from "../../../InfoPanel";

function Menu(props) {
    const [loader, setLoader] = useState(false);
    const [outro, setOutro] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const {t} = useTranslation();

    const submitHandle = () => {
        setConfirm(false);
        setLoader(true);

        axios({
            method: 'get',
            url: apiURL + `/module/${props.game.module}/section/tasks/complete`,
        })
            .then((response) => {
                props.dispatch(gameModulesLoad(response.data.modules));
                props.dispatch(authTeamLoaded(response.data.team));
                setLoader(false);
            });
    }

    return (
        <>
            <div className="wrapper__content m--0a d--flex fd--c">

                <div className="d--flex jc--sb ai--c">
                    <div className={"mt--3 mb--3"}>
                        <h1 className="heading--m mb--2">{t('level_section_tasks')}</h1>
                        {
                            props.auth.team.scenario && (
                                <p>
                                    {t('scenario_chosen')}: <strong>{t(`scenarios_${scenarios[props.auth.team.scenario - 1].code}_name`)}</strong>
                                </p>
                            )
                        }
                    </div>

                    <div>
                        {
                            props.game.module === 2 && props.updatable && (
                                <button className={"btn btn--m btn--primary btn--s btn--shadow mr--2"}
                                        onClick={() => {
                                            props.handleChangeScenario();
                                        }}
                                >
                                    {t('scenario_change')}
                                </button>
                            )
                        }

                        {
                            !props.auth.student &&
                            props.game.sectionStatus[props.game.module]['tasks'] !== 'completed'
                            && props.game.sectionStatus[props.game.module]['tasks'] !== 'locked'
                            && props.game.sectionStatus[props.game.module]['tasks'] !== 'evaluated'
                            && (
                                <button className={"btn btn--m btn--secondary btn--s btn--shadow"}
                                        onClick={() => {
                                            setConfirm(true);
                                        }}
                                >
                                    {t('submit')}
                                </button>
                            )
                        }

                        {
                            props.auth.student &&
                            (props.game.sectionStatus[props.game.module]['tasks'][props.auth.student.id] !== 'completed'
                            && props.game.sectionStatus[props.game.module]['tasks'][props.auth.student.id] !== 'locked'
                            && props.game.sectionStatus[props.game.module]['tasks'][props.auth.student.id] !== 'evaluated'
                            ) ? (
                                <button className={"btn btn--m btn--secondary btn--s btn--shadow"}
                                        onClick={() => {
                                            setConfirm(true);
                                        }}
                                >
                                    {t('submit')}
                                </button>
                            ) : (
                                <button className={"btn btn--m btn--text btn--s"}
                                        onClick={() => props.dispatch(gameModuleClose())}
                                >
                                    {t('close')}
                                </button>
                            )
                        }
                    </div>
                </div>

                <InfoPanel text={t('info_task')} classNames={['w--70']}/>
                {
                    props.submittedBy && (
                        <InfoPanel text={t('info_task_submitted') + " - " + props.submittedBy} classNames={['w--70']}/>
                    )
                }

                {
                    (
                        (props.basic.filter((task)=>(task.points)).length > 0)
                        ||
                        (props.advanced.filter((task)=>(task.points)).length > 0)
                    )
                     && (
                        <InfoPanel text={t('info_task_open_evaluation')} classNames={['w--70']}/>
                    )
                }


                {
                    props.game.module !== 6 && (
                        <>
                            <h2 className={"mb--2"}>{t('task_basic_heading')}</h2>
                            <div className="card-container fw--w d--flex mb--3">
                                {
                                    props.basic.map((task, i) => (
                                        <div key={i} className={"card settings-item d--flex fd--c jc--sb"}>
                                            <div className="item__content">
                                                <h2 className={"mb--2 d--flex ai--fs jc--sb"}>{task.name}
                                                    {
                                                        task.optional === 1 && (
                                                            <div
                                                                className={"alert alert--warning s ml--1 mt--1"}>{t('tasks-optional')}</div>
                                                        )
                                                    }
                                                </h2>
                                                <p className={"mb--2"}>{task.description}</p>

                                            </div>

                                            <div className="item__controls d--flex jc--sb">
                                                <button
                                                    className={"btn btn--s btn--secondary as--fs"}
                                                    onClick={() => props.handleOpen(task.id)}
                                                >
                                                    {t('task_btn_enter')}
                                                </button>


                                                {

                                                    (props.solutions[task.id] !== "" || task.answer !== null)
                                                    && task.points === null && (
                                                        <div className="d--flex fd--c jc--c ai--c">
                                                            <div className="label label--gray label--circle d--flex">
                                                                <div className="mdi mdi-check m--a"></div>
                                                            </div>
                                                            <span
                                                                className={"label__desc gray"}>{t('task_filled')}</span>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    task.points !== null && (
                                                        <div className="d--flex fd--c jc--c ai--c">
                                                            <div className="label label--success label--circle d--flex">
                                                                <div className="mdi mdi-check-all m--a"></div>
                                                            </div>
                                                            <span
                                                                className={"label__desc success"}>{t('task_evaluated')}</span>
                                                            <span
                                                                className={"label__desc success text--m"}><strong>{task.points}/{task.max_points}</strong></span>
                                                        </div>
                                                    )
                                                }


                                            </div>

                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    )
                }

                <h2 className={"mb--2"}>{t('task_advanced_heading')}</h2>
                <div
                    className={`card-container ${(!props.auth.team.is_advanced ? 'card--disabled__task' : '')} fw--w d--flex mb--3`}>
                    {
                        props.advanced.map((task, i) => (
                            <div key={i} className={"card settings-item d--flex fd--c jc--sb"}>
                                <div className="item__content">
                                    <h2 className={"mb--2 d--flex ai--fs jc--sb"}>{task.name}
                                        {
                                            task.optional === 1 && (
                                                <div
                                                    className={"alert alert--warning s ml--1 mt--1"}>{t('tasks-optional')}</div>
                                            )
                                        }
                                    </h2>

                                    <p className={"mb--2"}>{task.description}</p>
                                </div>

                                <div className="item__controls d--flex jc--sb">
                                    <button
                                        className={"btn btn--s btn--secondary as--fs"}
                                        onClick={() => props.handleOpen(task.id)}
                                    >
                                        {t('task_btn_enter')}
                                    </button>

                                    {
                                        props.auth.team.is_advanced === 1 && (props.solutions[task.id] !== "" || task.answer !== null) && task.points === null && (
                                            <div className="d--flex fd--c jc--c ai--c">
                                                <div className="label label--gray label--circle d--flex">
                                                    <div className="mdi mdi-check m--a"></div>
                                                </div>
                                                <span
                                                    className={"label__desc gray"}>{t('task_filled')}</span>
                                            </div>
                                        )
                                    }

                                    {
                                        props.auth.team.is_advanced === 1 && task.points !== null && (
                                            <div className="d--flex fd--c jc--c ai--c">
                                                <div className="label label--success label--circle d--flex">
                                                    <div className="mdi mdi-check-all m--a"></div>
                                                </div>
                                                <span
                                                    className={"label__desc success"}>{t('task_evaluated')}</span>
                                                <span
                                                    className={"label__desc success text--m"}><strong>{task.points}/{task.max_points}</strong></span>
                                            </div>
                                        )
                                    }
                                </div>

                            </div>
                        ))
                    }
                </div>


            </div>
            <img src={"/img/bg-open_task.png"} className={"img--bg-full"}/>
            {
                confirm && (
                    <div className="loader">
                        <div className={"loader__submit text--center"}>
                            <h2 className="text--xl mb--3">{t('task_submit_heading')}</h2>
                            <p className="mb--1 text--m">{t('task_submit_text1')}</p>
                            <p className="mb--3 text--m">{t('task_submit_text2')}</p>

                            <button
                                className="btn btn--m btn--secondary"
                                onClick={submitHandle}
                            >
                                {t('submit-confirmation')}
                            </button>

                            <button
                                className="btn btn--m btn--text white mr--1"
                                onClick={() => setConfirm(false)}
                            >
                                {t('cancel')}
                            </button>
                        </div>
                    </div>
                )
            }

            {
                loader && (
                    <div className="loader">
                        <div>
                            <h1>{t('loader_saving')}</h1>
                        </div>
                    </div>
                )
            }

            {
                outro && (
                    {/*<ModuleFinish className="empathy"/>*/}
                )
            }
        </>
    )
}


function mapStateToProps(state) {
    const {game, auth} = state;

    return {game, auth}
}

export default connect(
    mapStateToProps
)(Menu);