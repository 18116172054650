import React, {useRef, useState} from 'react';
import {authLogout} from "../Store/actions/authActions";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import useOutsideClick from "../Hooks/useOutsideClick";
import LocaleChange from "./LocaleChange";
import {gameLogout} from "../Store/actions/gameActions";

function NavbarDropdown( props ) {
    const {t, i18n} = useTranslation();
    const dropdownRef = useRef(null);
    const [active, setActive] = useState( false );

    useOutsideClick(dropdownRef, () => {
        setActive(false);
    })
    return (
        <div ref={dropdownRef} className={"menu__item d--flex dropdown " + ( active ? 'active' : 'inactive' )}>

            <a
                href="#"
                className={"d--flex ai--c"}
                onClick={( e ) => {
                    e.preventDefault();
                    setActive( !active );
                }}
            >
                <img src="img/user.svg" alt=""/>
                <span className={"ml--2 sm:d--none mr--2"}>
                    {props.auth.provider === 'students' ? props.auth.student.full_name : props.auth.team.game_name}
                </span>
                <img src="img/chevron-down.svg" className={"nav-icon__s dropdown-open__icon"} alt=""/>
            </a>

            <div className="dropdown-container">

                <div className="dropdown__item">
                    <a
                        href="/results"
                        >
                        <span className="mdi mdi-chart-bar mr--2"></span> {t( 'results_heading' )}
                    </a>
                </div>
                <div className="dropdown__item">
                    <a
                        href="/settings"
                        >
                        <span className="mdi mdi-settings-outline mr--2"></span> {t( 'settings_heading' )}
                    </a>
                </div>
                <div className="dropdown__item">
                    <a
                        href="#"
                        onClick={( e ) => {
                            e.preventDefault();
                            e.stopPropagation();

                            localStorage.removeItem( 'access_token' );
                            localStorage.removeItem( 'guard_provider' );
                            props.dispatch( authLogout() );
                            props.dispatch( gameLogout() );
                            setActive( false );
                        }}
                    >
                        <span className="mdi mdi-logout-variant mr--2"></span> {t( 'auth_logout' )}
                    </a>
                </div>

                {/*<div className="dropdown__item" style={{color:'black'}}>
                    <LocaleChange/>
                </div>*/}

            </div>

        </div>
    )
}


function mapStateToProps( state ) {
    const {auth, credit} = state;

    return {
        credit,
        auth
    }
}

export default connect(
    mapStateToProps
)( NavbarDropdown );